import { all } from "redux-saga/effects";
import searchSagas from './Search.sagas';
import companySagas from "./Company.sagas";
import usersSagas from "./Users.sagas";
import activitySagas from "./Activity.sagas";
import contactSagas from "./Contact.sagas";
import lookupSagas from "./Lookup.sagas";
import echelonSagas from "./Echelon.sagas";

export default function* rootSaga() {
    yield all([
        searchSagas(),
        companySagas(),
        usersSagas(),
        activitySagas(),
        contactSagas(),
        lookupSagas(),
        echelonSagas()
    ]);
}
