import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './GolbalHeader.css';
import {connect} from 'react-redux';
import AeroLogo from '../../../assets/AeroLogo.svg';
import oppTrophy from '../../../assets/oppTrophy.svg';
import trophy from '../../../assets/trophy.svg';
import Select, {
    components,
} from 'react-select';
import {SolAvatar, SolIcon} from '@solstice/sol-react';
import Typeahead from "../Typeahead/Typeahead";
import {
    headerCreateDropdownOptions,
    headerNavLinks,
    userProfileDropdownOptions,
    userRecordsDropdownOptions
} from "../../../helpers/headerOptions";
import aeroConfig from "../../../config/aeroConfig";
import Nav from "@rsuite/responsive-nav";
import 'rsuite/dist/rsuite.min.css';
import {bindActionCreators} from "redux";
import * as UsersActions from "../../../store/actions/Users.actions";

const GlobalHeader = (props) => {
    const [currentNav, setCurrentNav] = React.useState('');
    const handleOptionUrl = (val) => {
        if (val.url || val.path)
            window.open(val.url || `${aeroConfig.legacy_aero_uri}${val.path}`, '_self')
    }
    const { userProfile, history } = props;


    React.useEffect(() => {
        const { usersActions } = props;
        usersActions.getUserProfile();
    }, []);

    React.useEffect(() => {
        if(userProfile && userProfile?.firstName && !userProfile?.isAdministrator) {
            history.replace('/echelon')
        }
    }, [userProfile]);


    return (
        <>
        <div className='header-container'>
            <div
                onClick={() => {
                    history.replace('/');
                }}
                className='logo-container'>
                <img src={AeroLogo}/>
            </div>
            <div>
                <Typeahead/>
            </div>
            <div>
                <div style={{
                    backgroundColor: '#091E25',
                    display: 'flex'
                }}>
                    <div className='myrecords-container'>
                        <Select
                            onChange={(newValue) => {
                                handleOptionUrl(newValue);
                            }}
                            styles={{
                                input: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: '30px',
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: 'none !important',
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                    width: '125px',
                                    height: '38px',
                                    border: '1px solid transparent !important',
                                    backgroundColor: '#E3E5EE0F',
                                    ':hover': {
                                        border: '1px solid #006082 !important',
                                        backgroundColor: 'transparent',

                                    },
                                    '> div': {
                                        height: '38px',
                                    },
                                }),
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    borderRadius: '6px',
                                    ':hover': {
                                        borderColor: 'none !important',
                                        backgroundColor: 'transparent !important',
                                    }
                                }),

                                indicatorSeparator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    display: 'none',
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: 'transparent',
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#FFF',
                                    ':hover': '#FFF',
                                    backgroundColor: 'transparent',
                                    borderRadius: '10px'

                                }),
                                placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#FFF',
                                }),
                            }}
                            isClearable={false}
                            isSearchable={false}
                            placeholder={'My Records'}
                            autocomplete={'false'}
                            value={'My Records'}
                            options={userRecordsDropdownOptions}
                        />

                    </div>
                    <div className='create-container'>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    width: '105px',
                                    height: '38px !important',
                                    borderColor: 'none !important',
                                    boxShadow: 'none',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                    padding: '0px 8px 0px 7px !important',
                                    borderRadius: '10px',
                                    backgroundColor: '#08475E',
                                    ':hover': {
                                        borderColor: 'none !important',
                                        backgroundColor: '#08475E',

                                    },
                                    '> div': {
                                        padding: '0px !important',
                                        height: '38px'
                                    }
                                }),
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    border: 'none',
                                    backgroundColor: '#08475E',
                                    borderRadius: '6px',
                                    ':hover': {
                                        borderColor: 'none !important',
                                        backgroundColor: '#08475E !important',
                                    }
                                }),

                                indicatorSeparator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    display: 'none',
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '120px',
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: '#08475E',
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#09779E',
                                    ':hover': '#08475E',
                                    backgroundColor: '#08475E',
                                    borderRadius: '10px'

                                }),
                                placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: '#FFF',
                                }),
                            }}
                            isClearable={false}
                            isSearchable={false}
                            placeholder={'Create'}
                            autocomplete={'false'}
                            value={'Create'}
                            options={headerCreateDropdownOptions}
                            onChange={(newValue) => {
                                handleOptionUrl(newValue);
                            }}
                        />
                    </div>
                    <Select
                        isClearable={false}
                        isSearchable={false}
                        styles={{
                            input: (baseStyles, state) => ({
                                ...baseStyles,
                                height: '30px',
                            }),
                            menu: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '150px',
                                marginLeft: '-79px'
                            }),
                            placeholder: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'none',
                            }),
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                boxShadow: 'none',
                                '> div': {
                                    padding: '0px !important',
                                    outline: 'none',
                                    height: '38px !important',
                                },
                            }),

                            indicatorSeparator: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'none',
                            }),
                            dropdownIndicator: (baseStyles, state) => ({
                                ...baseStyles,
                                color: '#FFF',
                                ':hover': '#FFF',
                                padding: '2px 0px 0px 2px',
                                fontSize: '10px',
                                backgroundColor: 'transparent',
                                borderRadius: '10px',
                                width: '20px',
                                height: '20px'

                            }),
                        }}
                        components={{
                            Control: ({children, ...props}) => (
                                <components.Control {...props}>
                                    <div className='avatar-container'>
                                        <SolAvatar
                                            image=''
                                            style={{marginTop: '4px'}}
                                            name={`${userProfile?.firstName} ${userProfile?.lastName}`}
                                            status='none'
                                            size='28'
                                        />
                                    </div>
                                    {children}
                                </components.Control>
                            )
                        }}
                        value={''}
                        onChange={(newValue) => {
                            handleOptionUrl(newValue);
                        }}
                        options={userProfileDropdownOptions}
                        placeholder={''}
                    />
                </div>
            </div>
        </div>
            <div className='subheader-container'>
                <Nav onSelect={(eventKey) => {
                    setCurrentNav(eventKey);
                    if(eventKey === 'Home') {
                        history.push('/');
                    } else {
                        window.open(`${eventKey !== 'Home' ? aeroConfig.legacy_aero_uri: ''}${headerNavLinks[eventKey]}`, '_self')
                    }
                }} className='subheader-nav' appearance="subtle" justified defaultActiveKey="Home">
                    <Nav.Item eventKey="Home" active={currentNav === 'Home'}>
                        <SolIcon icon='home' size="20"/>
                        <div className="navText" style={{color: currentNav === 'Home' ? '#01151D': '#637177'}}>Home</div>
                    </Nav.Item>
                    <Nav.Item eventKey="Opportunity">
                        <img src={currentNav === 'Opportunity' ? trophy: oppTrophy}/>
                        <div className="navText" style={{color: currentNav === 'Opportunity' ? '#01151D': '#637177'}}>Opportunities</div>
                    </Nav.Item>
                    <Nav.Item eventKey="Financials">
                        <SolIcon icon="request_quote" size="20"/>
                        <div className="navText" style={{color: currentNav === 'Financials' ? '#01151D': '#637177'}}>Financials</div>
                    </Nav.Item>
                    <Nav.Item eventKey="Dashboard">
                        <SolIcon icon="dashboard" size="20" />
                        <div className="navText" style={{color: currentNav === 'Dashboard' ? '#01151D': '#637177'}}>Dashboard</div>
                    </Nav.Item>
                    <Nav.Item eventKey="Reports">
                        <SolIcon icon="assignment" size="20" />
                        <div className="navText" style={{color: currentNav === 'Reports' ? '#01151D': '#637177'}}>Reports</div>
                    </Nav.Item>
                    <Nav.Item eventKey="Tools">
                        <SolIcon icon="activity_zone" size="20" />
                        <div className="navText" style={{color: currentNav ? '#01151D': '#637177'}}>Tools</div>
                    </Nav.Item>
                </Nav>
            </div>
        </>
    )
}

GlobalHeader.propTypes = {
    history: PropTypes.object,
    searchActions: PropTypes.object,
    searchDetails: PropTypes.object,
};

GlobalHeader.defaultProps = {
    history: {},
    searchActions: {},
    searchDetails: {},
};

function mapStateToProps(state) {
    if (state.search) {
        return {
            userProfile: state.users.userProfile,
            searchDetails: state.search,
        };
    }
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(UsersActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalHeader);
