import api from "../../api/index";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    ADD_EDIT_ACTIVITY,
    addEditActivitySuccess,
    GET_ACTIVITY_BY_ID,
    getActivityByIdSuccess,
} from "../actions/Activity.actions";
import { getCompanyFeedSaga, getCompanyDetailsSaga, getCompanyContactDetailsSaga } from "./Company.sagas";

export function* addEditActivity(action) {
    try {
        if(action?.payload?.cb) {
            action?.payload?.cb(true);
        }
        const response = yield call(
            api.activityApi.addEditActivity,
            action.payload?.activityData
        );
        yield put(addEditActivitySuccess(response));
        yield call(getCompanyDetailsSaga, {payload: { id: action?.payload?.activityData?.regardingId?.toString()}});
        yield call(getCompanyFeedSaga, {payload: { id: action?.payload?.activityData?.regardingId?.toString(), limit: 15, offset: 1, refreshFeed: true }});
        yield call(getCompanyFeedSaga, { payload: { id: action?.payload?.activityData?.regardingId?.toString(), limit: 15, offset: 1, refreshFeed: true, activitiesOnly: true } });
        yield call(getCompanyContactDetailsSaga, { payload: { type: 'jllcontacts', companyId: action?.payload?.activityData?.regardingId?.toString(), limit: 15, offset: 0, isActive: true, reset: true } });
        if(action?.payload?.cb) {
            action?.payload?.cb(false);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* getActivityByIdSaga(action) {
    try {
        const response = yield call(
            api.activityApi.fetchActivityById,
            action.payload
        );
        yield put(getActivityByIdSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchAddEditActivity() {
    yield takeEvery(ADD_EDIT_ACTIVITY, addEditActivity);
}

export function* watchGetActivityById() {
    yield takeEvery(GET_ACTIVITY_BY_ID, getActivityByIdSaga);
}

export default function* usersSagas() {
    yield all([
        watchGetActivityById(),
        watchAddEditActivity()
    ]);
}