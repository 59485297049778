import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import './HomePage.css';
import {connect} from "react-redux";
import GlobalHeader from "../../components/Aero/GlobalHeader/GlobalHeader";

const AeroHomePage = (props) => {

    const { history } = props;

    return (
        <div>
            <GlobalHeader history={history} />
            <div className="col-8">

            </div>
            <div className="col-4">

            </div>
        </div>
    )
}

AeroHomePage.propTypes = {
    history: PropTypes.object,
    searchActions: PropTypes.object,
    searchDetails: PropTypes.object,
};

AeroHomePage.defaultProps = {
    history: {},
    searchActions: {},
    searchDetails: {},
};

function mapStateToProps(state) {
    if (state.search) {
        return {
            searchDetails: state.search,
        };
    }
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AeroHomePage);
